import { getValueByPath, setValueByPath } from "./utils";

// Returns the default parser function based on the type
function getDefaultParser<T>(opts: Partial<BinderReqs<T>>): ParseFunction {
    switch (opts.type) {
        case 'int':
            return Number.parseInt;
        case 'float':
            return Number.parseFloat;
        default:
            return (v: any) => v;
    }
}

// Returns the default value name based on the type
function getDefaultValueName(opts: Partial<BinderReqs<any>>): string {
    switch (opts.type) {
        case 'boolean':
            return 'toggled';
        case 'color':
            return 'color';
        default:
            return 'value';
    }
}

// Returns the default event name
function getDefaultEventName(opts: Partial<BinderReqs<any>>): string {
    return 'onChange';
}


// type GetDataFunction<T> = (store?: boolean) => T;
type SetDataFunction<T> = (data: T) => void;
type ParseFunction = (value: any) => any;
type ValueType = string | number | boolean | undefined | null;

interface BinderData<T> {
    data: T;
    setData: SetDataFunction<T>;
}

interface BinderOptions {
    type: 'string' | 'int' | 'float' | 'boolean' | 'color';
    eventName: string;
    valueName: string;
    parse?: ParseFunction;
    defaultValue?: ValueType;
}

type BinderInput<T> = BinderData<T> & Partial<BinderOptions>;

export function componentBinder<T>(
    key: string,
    { data, setData, ...opts }: BinderInput<T>,
): Record<string, any> {
    const options: BinderOptions = {
        eventName: getDefaultEventName(opts),
        valueName: getDefaultValueName(opts),
        type: 'string',
        parse: getDefaultParser(opts),
        defaultValue: undefined,
        ...opts,
    };
    const out: Record<string, any> = {
        [options.valueName]: getValueByPath(data, key, options.defaultValue),
        [options.eventName]: (e: unknown) => {
            let value: any;
            if (isEvent(e)) {
                value = e.target.value;
            } else {
                value = e;
            }

            const parsedValue = options?.parse?.(value) ?? value;
            const newData = { ...data };
            setValueByPath(newData, key, parsedValue);
            setData(newData);
        }
    };

    if (options.defaultValue) out.def = options.defaultValue;

    return out;
}

// Helper function to check if the input is an event
function isEvent(e: any): e is { target: { value: any } } {
    return e && e.target && typeof e.target.value !== 'undefined';
}
