import utils from './utils';

export * from './telynxSettings'


export const validateURL = (url?: string): boolean => {
    if (!url) {
        return false;
    }
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
};

export const validateEmail = (email?: string): boolean => {
    if (!email) {
        return false
    }
    return utils.emailRE.test(email);
};

export const validatePhoneNumber = (phone?: string): boolean => {
    if (!phone) {
        return false;
    }

    // E.164 format: +[country code][national number]
    const e164Regex = /^\+[1-9]\d{1,3}\d{7,14}$/;
    return e164Regex.test(phone);
};
