import history from '@/history';
import utils from './utils';

export class Linker {
	private url: URL;

	constructor(path: string, noOrigin = false) {
		const base = Linker.getBaseURL(noOrigin);
		this.url = Linker.createURL(path, base);
	}

	private static getBaseURL(noOrigin: boolean): string {
		if (typeof window === 'undefined') return '';
		return noOrigin ? window.location.href : window.location.origin;
	}

	private static createURL(path: string, base: string): URL {
		try {
			return new URL(path, base);
		} catch (error) {
			console.error('Invalid URL:', error);
			throw new Error('Failed to construct URL.');
		}
	}

	private formatURL(url: string, replacements: Record<string, string> = {}): string {
		const defaultReplacements: Record<string, string> = { ':uid': utils.uid };
		const mergedReplacements = { ...defaultReplacements, ...replacements };

		let formattedURL = url;
		for (const [key, value] of Object.entries(mergedReplacements)) {
			formattedURL = formattedURL.replace(key, value);
		}
		return formattedURL;
	}

	get searchParams(): URLSearchParams {
		return this.url.searchParams;
	}

	addParam(key: string, value: string | number | boolean): this {
		this.url.searchParams.append(key, value.toString());
		return this;
	}

	addParams(params: Record<string, string | number>): this {
		Object.entries(params).forEach(([key, value]) => {
			this.url.searchParams.append(key, value.toString());
		});
		return this;
	}

	setParam(key: string, value: string | number): this {
		this.url.searchParams.set(key, value.toString());
		return this;
	}

	removeParam(key: string): this {
		this.url.searchParams.delete(key);
		return this;
	}

	open(inNewTab = false): this {
		const target = inNewTab ? '_blank' : '_self';
		const formattedURL = this.formatURL(this.url.toString());
		window.open(formattedURL, target, 'noopener,noreferrer');
		return this;
	}

	openWithHistory(): void {
		const historyInstance = history || utils.history;
		if (!historyInstance) {
			console.error('History instance not available.');
			throw new Error('Cannot open URL with history.');
		}
		const formattedURL = this.formatURL(this.url.toString());
		const relativePath = formattedURL.replace(window.location.origin, '');
		historyInstance.push(relativePath);
	}

	toString(): string {
		return this.url.toString();
	}
}

export default Linker;
